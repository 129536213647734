import Button from "components/Button";
import { URL } from "const";
import { useEffect } from "react";

export default function NotFoundPage() {
  useEffect(() => {
    const main = document.getElementById("main");
    main.classList.add("bg-gradient-1__lighter");
    return () => main.classList.remove("bg-gradient-1__lighter");
  }, []);

  return (
    <div className="container relative mt-[10.8rem] flex-grow px-20 py-10 sm:mt-[12.8rem] md:px-58 md:py-20 lg:mt-78">
      <p className="mb-20">Oops!</p>
      <h1 className="mb-20 text-28 leading-34 lg:text-34 lg:leading-44">
        Sorry, the page you were looking for does not exist
      </h1>
      <p className="mb-40">
        If you have any questions, please contact us at{" "}
        <a href="mailto:daisy@oww.io" className="underline">
          daisy@oww.io
        </a>
      </p>
      <Button href={URL.ROOT} className="w-fit">
        Go to homepage
      </Button>
      <p
        className="pointer-events-none absolute inset-0 flex items-center justify-center text-[22rem] font-bold tracking-tighter opacity-10 sm:text-[30rem] md:text-[40rem] lg:text-[50rem]"
        style={{
          WebkitTextStroke: "2px #351111",
        }}
      >
        404
      </p>
    </div>
  );
}
